var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "pricing-plan"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "pricing-card",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-card', {
    attrs: {
      "align": "center"
    }
  }, [_c('h3', [_vm._v("Custom Plan")]), _c('b-card-text', [_vm._v(" Customized your plan ")]), _c('b-list-group', {
    staticClass: "list-group-circle text-left"
  }, [_c('b-list-group-item', [_vm._v(" Find potential leads with precision")]), _c('b-list-group-item', [_vm._v("Refine search using various filters")]), _c('b-list-group-item', [_vm._v(" Access information with active leads")]), _c('b-list-group-item', [_vm._v(" Intuitive and user-friendly interface")]), _c('b-list-group-item', [_vm._v(" Visualize lead locations on an interactive map")]), _c('b-list-group-item', [_vm._v(" Gain insights into competitors' activities")]), _c('b-list-group-item', [_vm._v(" Fully responsive mobile-optimized platform")]), _c('b-list-group-item', [_vm._v(" Reach a global audience in multiple languages")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Credits",
      "label-for": "bg-opacity",
      "label-cols-sm": "4",
      "label-cols-lg": "12"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "id": "bg-opacity",
      "type": "range",
      "number": "",
      "min": "100",
      "max": "10000",
      "step": "100"
    },
    model: {
      value: _vm.credits,
      callback: function callback($$v) {
        _vm.credits = $$v;
      },
      expression: "credits"
    }
  }), _c('b-input-group-append', {
    staticClass: "text-monospace",
    attrs: {
      "is-text": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.credits.toFixed(2)) + " ")])], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "pricing-card",
    attrs: {
      "sm": "12",
      "md": "6",
      "lg": "6"
    }
  }, [_c('b-card', [_c('div', {
    staticClass: "checkout-options"
  }, [_c('b-card', [_c('label', {
    staticClass: "section-label mb-1"
  }, [_vm._v("Details")]), _c('div', {
    staticClass: "price-details"
  }, [_c('h6', {
    staticClass: "price-title"
  }, [_vm._v("Price Details")]), _c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v("Sub Total Price")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v("$ " + _vm._s(_vm.subTotalPrice))])]), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v("Discount")]), _c('div', {
    staticClass: "detail-amt discount-amt text-success"
  }, [_vm._v(" -" + _vm._s(_vm.discountPrice) + " $ ")])]), _c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title"
  }, [_vm._v("Tax")]), _c('div', {
    staticClass: "detail-amt"
  }, [_vm._v("$ " + _vm._s(_vm.taxPrice))])])]), _c('hr'), _c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "price-detail"
  }, [_c('div', {
    staticClass: "detail-title detail-total"
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "detail-amt font-weight-bolder"
  }, [_vm._v(" $ " + _vm._s(_vm.totalPrice) + " ")])])]), _vm.isLoading ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    }
  }, [_c('b-spinner')], 1) : _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.buyNow(_vm.credits);
      }
    }
  }, [_vm._v(" Buy Now ")])], 1)])], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }