<template>
  <section id="pricing-plan">
    <!-- <div class="text-center pb-75">
      <h1 class="mt-5">Pricing Plan</h1>
      <p class="mb-2 pb-75">Start here instantly!</p>
    </div> -->

    <b-row>
      <b-col sm="12" md="6" lg="6" class="pricing-card">
        <b-card align="center">
          <h3>Custom Plan</h3>

          <b-card-text> Customized your plan </b-card-text>

          <b-list-group class="list-group-circle text-left">
            <b-list-group-item>
              Find potential leads with precision</b-list-group-item
            >
            <b-list-group-item
              >Refine search using various filters</b-list-group-item
            >
            <b-list-group-item>
              Access information with active leads</b-list-group-item
            >
            <b-list-group-item>
              Intuitive and user-friendly interface</b-list-group-item
            >
            <b-list-group-item>
              Visualize lead locations on an interactive map</b-list-group-item
            >
            <b-list-group-item>
              Gain insights into competitors' activities</b-list-group-item
            >
            <b-list-group-item>
              Fully responsive mobile-optimized platform</b-list-group-item
            >
            <b-list-group-item>
              Reach a global audience in multiple languages</b-list-group-item
            >
          </b-list-group>

          <b-form-group
            label="Credits"
            label-for="bg-opacity"
            label-cols-sm="4"
            label-cols-lg="12"
          >
            <b-input-group>
              <b-form-input
                id="bg-opacity"
                v-model="credits"
                type="range"
                number
                min="100"
                max="10000"
                step="100"
              />
              <b-input-group-append is-text class="text-monospace">
                {{ credits.toFixed(2) }}
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-card>
      </b-col>

      <b-col sm="12" md="6" lg="6" class="pricing-card">
        <b-card>
          <div class="checkout-options">
            <b-card>
              <label class="section-label mb-1">Details</label>

              <div class="price-details">
                <h6 class="price-title">Price Details</h6>
                <ul class="list-unstyled">
                  <li class="price-detail">
                    <div class="detail-title">Sub Total Price</div>
                    <div class="detail-amt">$ {{ subTotalPrice }}</div>
                  </li>
                  <li class="price-detail">
                    <div class="detail-title">Discount</div>
                    <div class="detail-amt discount-amt text-success">
                      -{{ discountPrice }} $
                    </div>
                  </li>
                  <li class="price-detail">
                    <div class="detail-title">Tax</div>
                    <div class="detail-amt">$ {{ taxPrice }}</div>
                  </li>
                </ul>
                <hr />
                <ul class="list-unstyled">
                  <li class="price-detail">
                    <div class="detail-title detail-total">Total</div>
                    <div class="detail-amt font-weight-bolder">
                      $ {{ totalPrice }}
                    </div>
                  </li>
                </ul>

                <b-button v-if="isLoading" variant="primary" block >
                  <b-spinner></b-spinner>
                </b-button>

                <b-button v-else variant="primary" block @click="buyNow(credits)">
                  Buy Now
                </b-button>
                
              </div>
            </b-card>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
  
  
  <script>
import {
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BForm,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "Subscriptions",
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    BForm,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroupAppend,
    BInputGroup,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      // Permissions

      email: "",
      packageId: "",
      companyName: "",

      nameError: false,
      emailError: false,

      pricing: {},

      packs: [],

      isLoading: false,

      credits: 100,
      perCreditPrice: 0,

      subTotalPrice: 0,
      discountPrice: 0,
      taxPrice: 0,
      totalPrice: 0,
    };
  },

  watch: {
    credits() {
      this.calculatePrices();
    },
  },

  computed: {
    ...mapGetters({
      authUser: "userModule/getUser",
    }),
  },

  async created() {
    try {
      this.loadItems();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    calculatePrices() {
      this.subTotalPrice = (this.credits * this.perCreditPrice).toFixed(2);
      this.totalPrice = (
        this.subTotalPrice -
        this.discountPrice +
        this.taxPrice
      ).toFixed(2);
    },
    async getSubscriptionPricingItems() {
      return await this.$api.get("api/package-pricing");
    },

    async loadItems() {
      const getSubscriptionPricingItems =
        await this.getSubscriptionPricingItems({});

      this.packs = getSubscriptionPricingItems.data;

      this.perCreditPrice = this.packs?.price / this.packs?.credit;

      this.calculatePrices();

    },

    async buyNow(credits) {
      this.isLoading = true;
      try {
        const response = await this.$api.post("/api/buy-pack", {
          email: this.authUser?.email,
          credits: credits,
        });

        if (response.data.status == "success") {
          window.location.href = response.data.checkout_url;
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "BellIcon",
            variant: "danger",
            text: "Buy Now didn't succeed",
          },
        });
      }

      this.isLoading = false;

    },
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.current {
  border: 1px solid #28c76f;
}

.price-details {
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin-bottom: 20px;
}

.price-details h6.price-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}

.price-details ul {
  padding-left: 0;
  list-style: none;
}

.price-details li.price-detail {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.price-details li.price-detail .detail-title {
  font-size: 14px;
  color: #6c757d;
}

.price-details li.price-detail .detail-amt {
  font-size: 14px;
  font-weight: 500;
  color: #495057;
}

.price-details li.price-detail .discount-amt {
  font-size: 14px;
  font-weight: 500;
  color: #28a745;
}

.price-details .detail-total {
  font-size: 16px;
  font-weight: 600;
}

.price-details .font-weight-bolder {
  font-weight: 700;
}

.price-details hr {
  margin-top: 10px;
  margin-bottom: 20px;
}

.price-details a.detail-amt {
  font-size: 14px;
  font-weight: 500;
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.price-details a.detail-amt:hover {
  text-decoration: underline;
}

.price-details b-button {
  margin-top: 20px;
}
</style>